import type { RouterConfig } from '@nuxt/schema'
import type { RouteRecordRaw } from 'vue-router'

export default {
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes: async (_routes) => {
    // check if it is admin
    let isAdmin = false
    if (import.meta.server) {
      const { ssrContext } = useNuxtApp()
      isAdmin = (ssrContext?.event.context.isAdmin === true)
    } else if (window.location.hostname.split('.')[0] === 'admin') {
      isAdmin = true
    }

    if (isAdmin) {
      return remap(_routes, 'admin')
    }

    const tenant = await useTenant()
    return remap(_routes, useLowerCase(tenant.value.solution))
  },
} satisfies RouterConfig


function remap(routes: readonly RouteRecordRaw[], filter: string) {
  return routes
    .filter((route) => route.path.startsWith(`/${filter}`))
    .map((route) => ({
      ...route,
      path: route.path.replace(`/${filter}`, '')
    }))
}