export const useTenant = async () => {
  let tenant: any = null
  if (import.meta.server) {
    const { ssrContext } = useNuxtApp()
    tenant = ssrContext?.event.context.tenant
  } else {
    const { data } = await useFetch(`/api/v1/web/tenants/${useSubdomain()}`)
    if (data) {
      tenant = data.value
    }
  }
  return useState('tenant', () => tenant)
}