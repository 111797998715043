export const useUser = async () => {
  const user = useState<any>('user', () => null)

  if (import.meta.server) {
    const event = useRequestEvent()
    user.value = usePick(event?.context.user, ['id', 'email', 'name', 'tenant'])
  } else {
    const token = useToken()
    if (token) {
      await callOnce('useUser', async () => {
        const { data, status } = await useFetch('/api/v1/user/account/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if (status.value === 'success') {
          user.value = usePick(data.value, ['id', 'email', 'name', 'tenant'])
        }
      })
    }
  }

  return user
}