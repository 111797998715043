const redirects: any = {
  '/': '/dashboard/overall',
  '/dashboard': '/dashboard/overall',
  // '/analytics': '/analytics/eia',
  '/configuration': '/configuration/device',
  '/alerts': '/alerts/overall'
}

export default defineNuxtRouteMiddleware((to, from) => {
  if (useSubdomain() === 'admin') return;

  const redirectPath = redirects[to.path]

  if (to.path.startsWith('/analytics')) {
    throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })
  }

  if (redirectPath) {
    return navigateTo(redirectPath)
  }
})