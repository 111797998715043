export const useAdmin = async () => {
  const admin = useState<any>('admin', () => null)

  if (import.meta.server) {
    const event = useRequestEvent()
    admin.value = event?.context.admin
  } else {
    const token = useToken()
    if (token) {
      // use token as key to call the function once
      // this will allow the function to be called when the token changes
      // i.e. when the user logs in or logs out
      await callOnce(token, async () => {
        const { data, status } = await useFetch('/api/v1/admin/account/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if (status.value === 'success') {
          admin.value = usePick(data.value, ['id', 'email', 'name'])
        }
      })
    }
  }

  return admin
}