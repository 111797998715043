export default defineNuxtRouteMiddleware(async (to) => {
  if (to.path === '/login') return;
  if (to.path === '/reset_password') return;
  if (useSubdomain() === 'admin') {
    const admin = await useAdmin()
    if (admin.value?.id) return
  } else {
    const user = await useUser()
    if (user.value?.id) return
  }

  navigateTo('/login')
})